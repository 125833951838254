import { Component } from '@angular/core';

@Component({
  selector: 'app-siderbar-default',
  templateUrl: './siderbar-default.component.html',
  styleUrls: ['./siderbar-default.component.css']
})
export class SiderbarDefaultComponent {

  navigateToSlack(){
    window.open('https://pulzoworkspace.slack.com/archives/CUB8B5YJX')
  }
}
