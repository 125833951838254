<app-header></app-header>

<div class="flex mt-2">
    <app-sidebar optionMenu="8"></app-sidebar>

    <div class="flex bg-light-bg items-center justify-center">
        <div class="w-4/5 mt-4 pb-8 flex flex-col items-center bg-white rounded-md">
            <div class="flex justify-between w-[90%] h-[30px] mt-6 mb-6">
                <h1 class="font-semibold">{{'firstNoteScroll.title' | transloco }}</h1>
                <button (click)="sendArticles()"
                    class="items-center w-44 h-10 border rounded bg-[#038652] flex button-active">
                    <span class="text-white text-center font-semibold text-sm m-auto">{{'firstNoteScroll.save' |
                        transloco }}</span>
                </button>
            </div>

            <div class="bg-white border-solid border-2 h-[80px] items-center flex flex-row w-[90%] rounded-md">
                <form [formGroup]="formGroup" class="w-full flex flex-row items-center">
                    <input placeholder="url" type="text" id="TemplateNameInfo" formControlName="url"
                        class="mt-1 p-2 ml-6 h-10 rounded-lg w-[460px] bg-light-light focus-within:text-black">
                    <div class="custom-alert">
                    </div>
                    <button (click)="addNew()" [disabled]="!formGroup.value.url"
                        [ngClass]="formGroup.value.url ? 'bg-green cursor-pointer' : 'bg-gray-400 cursor-not-allowed'"
                        class="h-9 shadow-lg rounded-md w-[150px] ml-6 text-sm text-white px-20 md:px-5">
                        {{ 'firstNoteScroll.addArticle' | transloco }}
                    </button>

                </form>
            </div>

            <div
                class="flex justify-between shadow-md w-[90%]  mt-6 bg-white rounded-sm flex-col items-center">
                <h1 class="w-full  pt-3 bg-gray-100 h-12 rounded-t-md"><span class="ml-4">Links</span></h1>
                <div class="w-[98%] p-4 h-[95%] mt-auto mb-auto flex flex-col overflow-y-auto">
                    <div *ngFor="let data of arrayArticles.articles"
                        class="flex flex-row justify-around items-center p-4">
                        <img src="{{data.image}}" class="rounded-md w-12 h-12" />
                        <h2 class="ml-4 font-bold">{{data.title}}</h2>
                        <span class="font-normal text-[12px] p-4">{{data.url}}</span>
                        <button (click)="removeArticleByArticleId(data.article)"
                            class="bg-red w-3 h-3 p-3 rounded-[2px] flex justify-center items-center text-white">x</button>
                    </div>
                    <hr>
                </div>
            </div>
        </div>

    </div>

</div>