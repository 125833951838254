<!-- <app-siderbar-coordinator *ngIf=" role==''"></app-siderbar-coordinator>
<app-siderbar-coordinator *ngIf=" role=='adminredaccion'" [optionMenu]="optionMenu"></app-siderbar-coordinator>
<app-siderbar-editor *ngIf=" role=='redactor'" [optionMenu]="optionMenu"></app-siderbar-editor>
<app-siderbar-comercial *ngIf=" role?.toLowerCase() === 'comercial'" [optionMenu]="optionMenu"></app-siderbar-comercial> -->


<ng-container [ngSwitch]="role?.toLowerCase()">
    <!-- Caso por defecto -->
    <app-siderbar-default *ngSwitchCase="''"></app-siderbar-default>
    
    <!-- Admin Redacción -->
    <app-siderbar-coordinator *ngSwitchCase="'adminredaccion'" [optionMenu]="optionMenu"></app-siderbar-coordinator>
  
    <!-- Redactor -->
    <app-siderbar-editor *ngSwitchCase="'redactor'"  [optionMenu]="optionMenu"></app-siderbar-editor>
  
    <!-- Comercial -->
    <app-siderbar-comercial *ngSwitchCase="'comercial'" [optionMenu]="optionMenu"></app-siderbar-comercial>
    
</ng-container>
  