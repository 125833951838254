import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-siderbar-comercial',
  templateUrl: './siderbar-comercial.component.html',
  styleUrls: ['./siderbar-comercial.component.css']
})
export class SiderbarComercialComponent {
  @Input() optionMenu: string | undefined;

  navigateToSlack(){
    window.open('https://pulzoworkspace.slack.com/archives/CUB8B5YJX')
  }

}
