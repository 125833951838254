import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { CreateMenu, DataMenu, DeleteMenu, ListMenu, SaveList, SearchMenu, UpdateMenu } from 'src/app/models/main-menu';
import { environment as env } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MainMenuService {
  private baseUrl = `${env.apiCerberoUrl}`;

  constructor(private http: HttpClient) { }

  getListMenu(): Observable<ListMenu>{
    return this.http.get<ListMenu>(`${this.baseUrl}/request?event=menu-list-sections`);
  }

  createNewMenu(menu: CreateMenu){
    return this.http.post<CreateMenu>(`${this.baseUrl}/request?event=menu-create-section`, menu);
  }

  getMenuById(id:number): Observable<SearchMenu>{
    return this.http.get<SearchMenu>(`${this.baseUrl}/request?event=menu-find-section-by-id&id=${id}`);
  }

  updateMenu(updateOneMenu: UpdateMenu){
    return this.http.put<UpdateMenu>(`${this.baseUrl}/request?event=menu-update-section`, updateOneMenu);
  }

  deleteOneMenu(body: DeleteMenu){
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      body: body 
    };
    return this.http.delete<DeleteMenu>(`${this.baseUrl}/request?event=menu-delete-section`, httpOptions);
  }

  saveMenu(data: SaveList[]): Observable<SaveList>{
    return this.http.put<SaveList>(`${this.baseUrl}/request?event=menu-reorder-position-sections`, data);
  }
}
