import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MainMenuService } from 'src/app/core/services/main-menu.service';
import { Links } from 'src/app/models/list-link';
import { CreateMenu, DataMenu, DeleteMenu, ListMenu, UpdateMenu } from 'src/app/models/main-menu';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-home-menu',
  templateUrl: './home-menu.component.html',
  styleUrls: ['./home-menu.component.css']
})
export class HomeMenuComponent implements OnInit {
  menuForm: FormGroup;
  links: Links[] = [];
  dataList: DataMenu[] = [];
  sectionParents: DataMenu[]=[]; 
  status: boolean = true;

  constructor(private fB:FormBuilder, private srvMenu: MainMenuService){
    this.menuForm = this.fB.group({
      id: [null],
      name: ['', Validators.required],
      title: ['', Validators.required],
      title_h1: ['', Validators.required],
      description: ['', Validators.required],
      header: ['', Validators.required],
      location: ['', Validators.required],
      parent_id: [0],
      slug: [''], 
      status: [''],
    })
  }

  ngOnInit(): void {
    this.listMenu();

  }

  drop(event: CdkDragDrop<string[]>) {
  moveItemInArray(this.dataList, event.previousIndex, event.currentIndex);
  this.saveReorderedMenu();
}


  listMenu(){
    this.srvMenu.getListMenu().subscribe((response: ListMenu)=> { 
      this.dataList = response.data;
      console.log('lista',  this.dataList);
      this.listSectionParents();
    })
  }

  saveReorderedMenu() {
    if (this.dataList.length > 0) {
      this.srvMenu.saveMenu(this.dataList).subscribe(
        (response) => {
          Swal.fire('', 'Orden del menú actualizado con éxito', 'success');
          console.log('Respuesta del servidor:', response);
        },
        (error) => {
          Swal.fire('', 'Ocurrió un error al guardar el orden del menú', 'error');
          console.error('Error al guardar el menú:', error);
        }
      );
    } else {
      Swal.fire('', 'No hay secciones para guardar', 'warning');
    }
  }
  

  listSectionParents(){
    for (let i = 0; i < this.dataList.length; i++) {
     // console.log('section', this.dataList[i].parent_id);
      if(!this.dataList[i].parent_id ){
        this.sectionParents.push(this.dataList[i]);
      }
      
    }
  }

  //Validación campos
  checkValidation(option: number, field: any) {
    if (option == 1) {
      return field.invalid && (field.dirty || field.touched);
    } else {
      return field.errors?.['required'];
    }
  }

  getEdit(id:number){
    if (id != null) {
      this.srvMenu.getMenuById(id).subscribe((response)=>{
    
        this.menuForm.patchValue({
          id: id,
          name: response.data.name,
          title: response.data.title,
          title_h1: response.data.title_h1,
          description: response.data.description,
          header: response.data.header,
          location: response.data.location,
          parent_id: response.data.parent_id,
          slug: response.data.slug,
          status: response.data.status
        }) 
        //console.log('getedit', response.data);
      });
    }
  }

  submitForm(){
    this.trimFormValues();

    if (this.menuForm.invalid) {
      Swal.fire('', 'Falta información, complete los campos', 'warning');
    } else {

      if (this.menuForm.get('id')?.value != null) {
        const updateItem: UpdateMenu = {
          id: this.menuForm.get('id')?.value,
          name: this.menuForm.get('name')?.value,
          slug: this.menuForm.get('slug')?.value,
          title: this.menuForm.get('title')?.value,
          title_h1: this.menuForm.get('title_h1')?.value,
          description: this.menuForm.get('description')?.value,
          parent_id: parseInt(this.menuForm.get('parent_id')?.value),
          header: this.menuForm.get('header')?.value,
          location: this.menuForm.get('location')?.value,
          status: this.menuForm.get('status')?.value,
        }

       // console.log('update', updateItem);
        this.srvMenu.updateMenu(updateItem).subscribe(()=>{
          Swal.fire("", "Item del menú actualizado con exito", "success")
          .then(() => {
            window.location.reload();
          });
        })
      } else {
          if(this.menuForm.valid){
          const newItem: CreateMenu = {
            name: this.menuForm.get('name')?.value,
            title: this.menuForm.get('title')?.value,
            title_h1: this.menuForm.get('title_h1')?.value,
            description: this.menuForm.get('description')?.value,
            header: this.menuForm.get('header')?.value,
            parent_id: parseInt(this.menuForm.get('parent_id')?.value),
            location: this.menuForm.get('location')?.value,
            slug: this.menuForm.get('slug')?.value,
          };
          //console.log('crear', newItem);

          this.srvMenu.createNewMenu(newItem).subscribe(()=>{
            Swal.fire("", "Item del menú creado con exito", "success")
            .then(() => {
              window.location.reload();
            });
          });
        }

      }

    }

  }

  trimFormValues() {
    Object.keys(this.menuForm.controls).forEach(key => {
      const control = this.menuForm.get(key);
      if (control && typeof control.value === 'string') {
        control.setValue(control.value.trim());
      }
    });
  }

  delete(id:number){
    const body: DeleteMenu = { id: id };

    Swal.fire({
      customClass: {
        confirmButton: "#FF0000",
        cancelButton: "#008000"
      },
      text: '¿Está seguro de eliminar item del menú?',
       icon: 'warning',
       showCancelButton: true,
       confirmButtonText: 'Aceptar',
       cancelButtonText: 'Cancelar',
     }).then((confirmDelete) => {
       if (confirmDelete.isConfirmed) {
        this.srvMenu.deleteOneMenu(body).subscribe(()=> {
           Swal.fire({
             text: 'Se ha eliminado el item del menú con éxito',
             icon: 'success',
           }).then(() => {
            window.location.reload();
          });
        });
     }
    });
    
  }
}
