import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthGuard } from './core/guards/auth.guard';
import { HasRoleGuard } from './core/guards/has-role.guard';
import { RouterModule, Routes } from '@angular/router';
import { MainLayoutComponent } from './layout/components/main-layout/main-layout.component';
import { HomeComponent } from './modules/home/home.component';
import { HomeNewsletterComponent } from './modules/newsletter/home-newsletter/home-newsletter.component';
import { CreateNewsletterComponent } from './modules/newsletter/create-newsletter/create-newsletter.component';
import { AdminNewsletterComponent } from './modules/newsletter/admin-newsletter/admin-newsletter.component';
import { CreateGalleryComponent } from './modules/gallery/create-gallery/create-gallery.component';
import { HomeGalleryComponent } from './modules/gallery/home-gallery/home-gallery.component';
import { HomeSeoComponent } from './modules/seo/home-seo/home-seo.component';
import { HomeModulesEditorComponent } from './modules/module-management/home-modules-editor/home-modules-editor.component';
import { NewTemplateComponent } from './modules/module-management/new-template/new-template.component';
import { HomeModulesCoordinadorComponent } from './modules/module-management/home-modules-coordinador/home-modules-coordinador.component';
import { HomeVersionerComponent } from './modules/versioner-module/home-versioner/home-versioner.component'; //
import { ArticleVersionerComponent } from './modules/versioner-module/article-versioner/article-versioner.component';
import { CreateModuleComponent } from './modules/module-management/create-module/create-module.component';
import { EditTemplateComponent } from './modules/module-management/edit-template/edit-template.component';
import { NewModuleComponent } from './modules/module-management/new-module/new-module.component';
import { DeleteModalComponent } from './modules/shared/delete-modal/delete-modal.component';
import { EditModuleComponent } from './modules/module-management/edit-module/edit-module.component';
import { HomeLinksListComponent } from './modules/links-list/home-links-list/home-links-list.component';
import { CreateLinkListComponent } from './modules/links-list/create-link-list/create-link-list.component';
import { EditLinkListComponent } from './modules/links-list/edit-link-list/edit-link-list.component';
import { HomeMenuComponent } from './modules/main-menu/home-menu/home-menu.component';
import { FirstNoteScrollComponent } from './modules/first-note-scroll/first-note-scroll/first-note-scroll.component';
import { NotFoundComponent } from './modules/errors/not-found/not-found.component';
import { InternalServerComponent } from './modules/errors/internal-server/internal-server.component';



const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'home' },
  {
    path: '',
    component: MainLayoutComponent,
    children: [
      {
        canActivate: [AuthGuard],
        path: 'home',
        component: HomeComponent,
      },
      /* Módulo errores */
      {
        path: 'not-found',
        component: NotFoundComponent,
      },
      {
        path: 'internal-server',
        component: InternalServerComponent,
      },
      /* Módulo Newsletter */
      {
        canActivate: [AuthGuard],
        path: 'home-newsletter',
        component: HomeNewsletterComponent,
      },
      {
        canLoad:[HasRoleGuard],
        canActivate:[HasRoleGuard],
        data:{
          allowedRoles:  ['redactor','adminredaccion']
        },
        path: 'create-newsletter',
        component: CreateNewsletterComponent,
      },
      {
        canLoad:[HasRoleGuard],
        canActivate:[HasRoleGuard],
        data:{
          allowedRoles:  ['redactor','adminredaccion']
        },
        path: 'admin-newsletter',
        component: AdminNewsletterComponent,
      },
      /* Módulo Galerias */
      {
        canActivate: [AuthGuard],
        path: 'home-gallery',
        component: HomeGalleryComponent,
      },
      {
        canActivate: [AuthGuard],
        path: 'create-gallery',
        component: CreateGalleryComponent,
      },
      /* Módulo Seo */
      {
        canActivate: [AuthGuard],
        path: 'home-seo',
        component: HomeSeoComponent,
      },
      /*  Gestor de modulos  */ 
      // Rol editor
      {
        canLoad:[HasRoleGuard],
        canActivate:[HasRoleGuard],
        data:{
          allowedRoles: ['redactor', 'comercial']
        },
        path: 'home-module-editor',
        component: HomeModulesEditorComponent,
      },
      {
        canActivate:[HasRoleGuard],
        data:{
          allowedRoles: ['redactor', 'comercial']
        },
        path: 'create-module',
        component: CreateModuleComponent,
      },
      {
        canActivate:[HasRoleGuard],
        data:{
          allowedRoles: ['redactor', 'comercial']
        },
       path: 'new-module',
       component: NewModuleComponent,
     },

      {
        canLoad:[HasRoleGuard],
        canActivate:[HasRoleGuard],
        data:{
          allowedRoles: ['redactor', 'comercial']
        },
        path: 'edit-module',
        component: EditModuleComponent,
      },

      // Rol Admin/coordinador
      {
        canLoad:[HasRoleGuard],
        canActivate:[HasRoleGuard],
        data:{
          allowedRoles: ['adminredaccion']
        },
        path: 'home-module-coordinador',
        component: HomeModulesCoordinadorComponent,
      },

      {
        canLoad:[HasRoleGuard],
        canActivate:[HasRoleGuard],
        data:{
          allowedRoles: ['adminredaccion']
        },
        path: 'new-template',
        component: NewTemplateComponent,
      },
      
      {
        canLoad:[HasRoleGuard],
        canActivate:[HasRoleGuard],
        data:{
          allowedRoles: ['adminredaccion']
        },
        path: 'edit-template',
        component: EditTemplateComponent,
      },

      /* Hace parte del módulo compartido */
      {
        canLoad:[HasRoleGuard],
         canActivate:[HasRoleGuard],
         data:{
           allowedRoles:  ['adminredaccion','redactor']
         },
         path:'delete-modal',
         component: DeleteModalComponent,
      },
      
    
      /* Módulo Listado de enlaces */
      {
        canLoad:[HasRoleGuard],
        canActivate:[HasRoleGuard],
        data:{
          allowedRoles: ['adminredaccion','redactor']
        },
        path: 'list-links',
        component: HomeLinksListComponent,
      },
      {
        canLoad:[HasRoleGuard],
        canActivate:[HasRoleGuard],
        data:{
          allowedRoles: ['adminredaccion','redactor']
        },
        path: 'create-list',
        component: CreateLinkListComponent,
      },
      {
        canLoad:[HasRoleGuard],
        canActivate:[HasRoleGuard],
        data:{
          allowedRoles: ['adminredaccion','redactor']
        },
        path: 'edit-list',
        component: EditLinkListComponent,
      },
      /* Módulo Menú pulzo */
      {
        canLoad:[HasRoleGuard],
        canActivate:[HasRoleGuard],
        data:{
          allowedRoles: ['adminredaccion']
        },
        path: 'main-menu',
        component: HomeMenuComponent,
      },
       /* Módulo Primera Nota Scroll */
      {
        canLoad:[HasRoleGuard],
        canActivate:[HasRoleGuard],
        data:{
          allowedRoles: ['adminredaccion', 'comercial']
        },
        path: 'first-note-scroll',
        component: FirstNoteScrollComponent,
      },
      /* Módulo Versionador */
      {
        canLoad:[HasRoleGuard],
        canActivate:[HasRoleGuard],
        data:{
          allowedRoles: ['redactor']
        },
        path: 'home-versioner',
        component: HomeVersionerComponent,
      },
      {
        canLoad:[HasRoleGuard],
        canActivate:[HasRoleGuard],
        data:{
          allowedRoles: ['redactor']
        },
        path: 'article-versioner',
        component: ArticleVersionerComponent,
      },

    ],

  },
]

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(routes, {
      anchorScrolling: 'enabled',
      scrollPositionRestoration: 'enabled',
    }),
    CommonModule
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
