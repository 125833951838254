export const environment = {
  production: true,
  staging: false,
  apiCerberoUrl: 'https://cerbero.pulzo.com/cerbero/v2',
  scrollArticlesPath : 'https://s3-us-west-2.amazonaws.com/filesstaticpulzo/pulzo-lite/jsons/admin/scroll_articles.json',
  pageSize: 25,
  release: '3.4.5',
  sentry: 'https://5c9d7582d3f54ebba23055f17d8c2e03@o1114920.ingest.sentry.io/6293326',
  secretKey: 'jbe4jb43hjb56oie5jtil34894',
  nameAppHub: 'RedaccionApp',
  apiKey:'RedaccionApp',
  cerberoFrontURL: 'https://hub.pulzo.com',
  geaAppURL: 'https://mercurio.pulzo.com',
  permissionsNewsletter:['coordinadorredaccion','redactor'],
  permissionsModuleManagement:['coordinadorredaccion','redactor'],
  permissions: [{
    role:"coordinadorredaccion",
    actions:{
      edit:true,
      delete:true,
      create:true,
      program: true
    },
  },
  {
    role:"redactor",
    actions:{
      edit:false,
      delete:false,
      create:false,
      program: true
    },
  }
  ],
  
  permissionsModuleManager: [{
    role:"coordinadorredaccion",
    actions:{
      edit:true,
      delete:true,
      create:true,
      setting: true,
      manage: false
    },
  },
  {
    role:"redactor",
    actions:{
      edit:false,
      delete:false,
      create:false,
      setting: false,
      manage: true
    },
  }
  ],  
};